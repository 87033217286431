import MDRouter from "@/service/router";
import { mapState } from "vuex";
import { postOrder, get } from "@/utils/apiBase.js";
import lowerdev from "@/service/lowerdev";
import domain from "@/service/domain";
import { env, domClientWidth, domPixelRatio } from "md-base-tools/env";
import { mdaftersaleOrders } from "@api";
// eslint-disable-next-line no-unused-vars
import { showConfirmDialog, showToast } from "vant";
import orderProjectRefund from "@/components/order_detail/orderProjectRefund";
import DialogBtn from "@/components/order_detail/dialogBtn";
import itemli from "@/components/order/itemli";
import { mdaftersaleCancel, mdaftersalDetail } from "@/api/index";
export default {
  name: "orderList",
  components: {
    itemli,
    DialogBtn,
    orderProjectRefund
  },
  data() {
    return {
      env: env,
      isMobile: env.isMobile(),
      list: [],
      placeholder: "~@assets/images/orderlist/default_1x1@2x.png",
      io: null,
      tablist: [],
      tab_status: [],
      nav: 0,
      order_id: "",
      miniProgram: "",
      appealShow: false,
      oneListCode: "",
      refundMsg: "",
      refundInfo: null,
      orderProjectRefundShow: false,
      pay_resource: "alipay",
      dialog_btn: [],
      addressBtn: [{
        name: "去地址簿选地址",
        title: "去地址簿选地址",
        type: 1
      }, {
        name: "编辑当前订单地址",
        title: "编辑当前订单地址",
        type: 0
      }],
      oneListShow: false,
      dialog_address: false,
      dialog_show: false,
      remarkShow: false,
      remark: "",
      telShow: false,
      orderRefundShow: false,
      loading: false,
      is_next: true,
      payShow: false,
      giftWrapShow: false,
      openwxmina: false,
      page_index: 1,
      orderInfo: {},
      orderitem: {},
      html: "",
      orderType: "",
      order_type: "",
      orderId: "",
      aftersaleAxios: true,
      afterSaleListItem: {} // 点击”申诉“按钮，回来后，item信息
    };
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId
    }),
    if_show() {
      return !(this.env.isWeibo() || this.env.isWeixin() || this.env.isModianIos() || this.env.isModianAndroid());
    },
    if_no_header() {
      return !(this.isMobile || this.env.isModianIos() || this.env.isModianAndroid());
    },
    imgWidth() {
      return Math.round(domClientWidth() * domPixelRatio());
    }
  },
  beforeMount() {
    if (sessionStorage.getItem("miniProgram") === "weixin") {
      lowerdev.dynamicScript("https://res.wx.qq.com/open/js/jweixin-1.4.0.js");
    }
  },
  mounted() {
    lowerdev.addbind(window, "scroll", this.reloadScroll);
    this.getList();
    if (this.env.isModianIos() || this.env.isModianAndroid()) {
      document.addEventListener("visibilitychange", () => this.updateList());
    }
  },
  beforeUnmount() {
    lowerdev.removebind(window, "scroll", this.reloadScroll);
    document.removeEventListener('visibilitychange', () => this.updateList());
  },
  methods: {
    updateList() {
      if (document.visibilityState === "visible" && this.aftersaleAxios) {
        if (sessionStorage.getItem('afterSaleListItem')) {
          const {
            index,
            aftersale_id
          } = JSON.parse(sessionStorage.getItem('afterSaleListItem'));
          console.log('index', index);
          console.log('aftersale_id', aftersale_id);
          this.getAftersalDetail(this.afterSaleListItem.aftersale_id, this.afterSaleListItem.index);
        }
      }
    },
    async getAftersalDetail(aftersale_id, index) {
      this.aftersaleAxios = false;
      const {
        status,
        data,
        message
      } = await mdaftersalDetail({
        aftersale_id: aftersale_id
      });
      sessionStorage.removeItem('afterSaleListItem');
      if (status == 0) {
        this.list[index] = {
          ...this.list[index],
          btn_list: data.btn_list
        };
      } else {
        console.log(message);
      }
    },
    close() {
      showToast({
        type: "text",
        message: "操作成功",
        onClose: () => {
          window.location.reload();
        }
      });
    },
    jumpShop(id) {
      location.href = domain.wap + "/product/pop/" + id + ".html";
    },
    jumpProduct() {},
    show_dialog({
      arr,
      id,
      item,
      type
    }) {
      let arrStore = JSON.parse(JSON.stringify(arr));
      arrStore = arrStore.splice(0, arrStore.length - 2);
      this.dialog_btn = arrStore;
      this.orderitem = item;
      this.order_id = id;
      this.dialog_show = true;
      this.orderType = type;
    },
    close_dialog() {
      this.dialog_show = false;
    },
    op({
      type,
      item,
      orderType,
      listIndex,
      data
    }) {
      console.log(type, item, orderType, listIndex);
      console.log(333, item);
      switch (type) {
        case "md_aftersale_appeal":
          if (data.url) {
            const url = `/after_sale/appeal/${item.aftersale_id}?order_id=${item.order_info.order_id}`;
            // 记录进入了---申诉
            console.log('listIndex', listIndex);
            sessionStorage.setItem('afterSaleListItem', JSON.stringify({
              index: listIndex,
              aftersale_id: item.aftersale_id
            }));
            MDRouter.Link(url, 'wap');
          }
          break;
        case "md_aftersale_cancel":
          // 撤销申请
          mdaftersaleCancel({
            aftersale_id: item.aftersale_id
          }).then(res => {
            if (res.status == 0) {
              const list = this.list[listIndex].btn_list;
              const index = list.findIndex(item => item.type == type);
              list.splice(index, 1);
              showToast("操作成功");
            } else {
              showToast(res.message);
            }
          });
          break;
        default:
          MDRouter.Link(data.url);
      }
    },
    back() {
      this.$router.back(-1);
    },
    gotoDetails({
      item
    }) {
      // 如果有退款id，跳转至退款详情
      if (item.refund_id) {
        if (item.business_code == 4) {
          // 电商退款详情
          // eslint-disable-next-line
          graft.app({
            functionName: "openController",
            functionParams: `md://mall_refund_order?id=${item.refund_id}`,
            webFun: () => {
              location.href = `${domain.wap}/product/refund/${item.refund_id}`;
            }
          });
        } else {
          // 众筹退款详情
          // eslint-disable-next-line
          graft.app({
            functionName: "openController",
            functionParams: `md://refund_order?order_id=${item.order_id}&refund_id=${item.refund_id}`,
            webFun: () => {
              location.href = `${domain.wap}/user_order/refund_detail/${item.order_id}/${item.refund_id}`;
            }
          });
        }
      } else {
        // 否则跳转至售后详情
        const url = `/after_sale/detail?aftersale_id=${item.aftersale_id}&full_screen=true`;
        MDRouter.Link(url, 'wap');
      }
    },
    getList() {
      if (this.loading && !this.is_next) return;
      this.loading = true;
      mdaftersaleOrders({
        page: this.page_index,
        page_size: 10,
        json_type: "1"
      }).then(({
        status,
        data
      }) => {
        if (status == 0) {
          this.page_index++;
          this.is_next = data.is_next;
          const arr = data.list;
          if (arr && arr.length) {
            this.list.push(...arr);
            this.loading = false;
          }
        }
      });
    },
    refundFn(e) {
      const orderId = this.orderId;
      showConfirmDialog({
        message: "确定要申请退款吗？"
      }).then(() => {
        get("/apis/mdorder/related_reward", {
          order_id: orderId
        }).then(res => {
          if (res.data && res.data.list && res.data.list.length) {
            this.refundMsg = e;
            this.refundInfo = res.data;
            this.orderProjectRefundShow = true;
          } else {
            var searchParams = {
              url: "/apis/mdorder/refund/create",
              method: "post",
              props: {
                order_id: orderId,
                refund_reason: e
              }
            };
            postOrder(searchParams.url, searchParams.props).then(resa => {
              if (resa) {
                window.location.reload();
                showToast("操作成功");
              }
            });
            this.orderRefundShow = false;
          }
        });
      });
    },
    orderDetailCallback(fn) {
      const orderId = this.orderId;
      if (this.orderInfo.shop_info.skus.length === 1) {
        fn();
      } else {
        window.location.href = `${domain.wap}/product/evaluate/list?order_id=${orderId}`;
      }
    },
    PaySuccessBack() {
      window.location.href = `${domain.wap}/product/payment/success?pay_id=${this.pay_id}`;
    },
    // 客服按钮
    downApp() {
      const msg = "请移驾到「摩点App」中联系客服吧，小姐姐会快速回复你呦～";
      if (sessionStorage.getItem("miniProgram")) {
        showToast({
          msg: msg
        });
      } else {
        lowerdev.confirm({
          msg: msg,
          success: "打开App",
          cancel: "取消"
        }, () => {
          location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.modian.app";
        });
      }
    },
    reloadScroll() {
      const localTop = document.documentElement.scrollTop || document.body.scrollTop;
      const domHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      const domClientHeight = document.documentElement.clientHeight || document.body.clientHeight;
      if (this.loading || !this.is_next) {
        return false;
      }
      // console.log(localTop, domHeight, domClientHeight)
      if (localTop && domHeight < localTop + domClientHeight + 50) {
        this.getList();
      }
    }
  }
};