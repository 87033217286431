import { env, domClientWidth, domPixelRatio } from "md-base-tools/env";
import itemlititle from "@/components/order/itemlititle";
import domain from "@/service/domain";
export default {
  name: "itemli",
  data() {
    return {
      env
    };
  },
  components: {
    itemlititle
  },
  watch: {},
  computed: {
    imgWidth() {
      return Math.round(domClientWidth() * domPixelRatio());
    }
  },
  props: {
    item: {
      type: [Object, undefined],
      default: undefined
    },
    nav: {
      type: Number,
      default: 100
    },
    type: {
      type: String,
      default: "100"
    },
    listIndex: {
      type: Number,
      default: NaN
    }
  },
  mounted() {
    this.$nextTick(() => {});
  },
  methods: {
    show_dialog(e) {
      console.log(e);
      this.$emit("show_dialog", e);
    },
    op(e) {
      e.listIndex = this.listIndex;
      this.$emit("op", e);
    },
    gotoDetails(e) {
      this.$emit("gotoDetails", e);
    },
    jumpShop(id) {
      location.href = domain.wap + "/product/pop/" + id + ".html";
    },
    clickfn(type) {
      // eslint-disable-next-line vue/no-mutating-props
      this.pay_resource = type;
    },
    payClick() {
      this.$emit("payClick", this.pay_resource);
    }
  }
};