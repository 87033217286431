import MDRouter from "@/service/router";
import { env } from "md-base-tools/env";
import domain from "@/service/domain";
export default {
  name: "itemlititle",
  data() {
    return {
      env
    };
  },
  props: {
    item: {
      type: [Object, undefined],
      default: undefined
    },
    nav: {
      type: Number,
      default: 100
    }
  },
  mounted() {
    this.$nextTick(() => {});
  },
  methods: {
    jumpShop(id) {
      location.href = domain.wap + "/product/pop/" + id + ".html";
    },
    jumpProList() {
      // location.href = domain.wap + "/project/list";
      MDRouter.goProjectList();
    }
  }
};